import React, { useCallback, useMemo } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectFormState } from "../lib/store/selectors/formSelector";
import { setFormValue } from "../lib/store/actions/formAction";
import useResponsiveFonts from "../hooks/useResponsiveFonts";
import { NoSVG, YesSVG } from "../components/microcomponents/SVGcomponents";

interface AntiObesityMedicationQuestionProps {
  availableHeight: number;
  availableWidth: number;
  marginTop?: number;
}

const Allergyglp: React.FC<AntiObesityMedicationQuestionProps> = ({ availableHeight, availableWidth }) => {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const question = form.Dynamic_Questions[0].Sugestive.find(
    (q: any) => q.Main_Question === 'Have you ever had an adverse or allergic reaction to another GLP-1 receptor agonist?'
  );
  
  const selectedValue = question ? question.Answer_ID : "";

  const handleFieldUpdate = useCallback(
    (value: boolean) => {
      const answerValue = value ? "Yes" : "No"; 

      const updatedQuestions = form.Dynamic_Questions.map((dq: any) => {
        if (dq.Sugestive) {
          return {
            ...dq,
            Sugestive: dq.Sugestive.map((q: any) => 
              q.Main_Question === 'Have you ever had an adverse or allergic reaction to another GLP-1 receptor agonist?'
                ? { ...q, Answer_ID: answerValue }
                : q
            ),
          };
        }
        return dq;
      });
  
      dispatch(
        setFormValue({
          field: "Dynamic_Questions",
          value: updatedQuestions,
        })
      );
  
      navigate(value ? "/disqualified" : "/healthhistory");
    },
    [dispatch, form.Dynamic_Questions, navigate]
  );

  const desktopFontConfig = useMemo(
    () => ({
      heading: 28,
      question: 28,
      buttonText: 20,
      subquestion: 18,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 20,
      question: 18,
      buttonText: 14,
      subquestion: 12,
    }),
    []
  );

  // Calculate font sizes based on the device
  const fontSizes = useResponsiveFonts(
    desktopFontConfig, mobileFontConfig, 
    availableHeight, availableWidth, 
    800, 1200, 600, 400, 12, 72, 768
  );

  return (
    <div
      className="flex flex-col items-center mt-[9rem]"
      style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
    >
      <Box display="flex" flexDirection="column" textAlign="center" className="max-w-5xl" mt={10}>
        {/* Question Text */}
        <Text fontWeight="normal" mb={12} className="text-center  text-[#333]" style={{ fontSize: fontSizes.question }}>
          Have you ever had an adverse or allergic reaction to another GLP-1 receptor agonist?
        </Text>
        <p className="text-center text-gray-600 mb-8 max-w-4xl" style={{ fontSize: fontSizes.subquestion }}>
          Such as, but without limitation to, Dulaglutide (Trulicity), Exenatide extended release (Bydureon bcise), Exenatide (Byetta), Liraglutide (Victoza, Saxenda), and Lixisenatide (Adylyxin)?
        </p>

        {/* Radio Button Options for Yes/No */}
        <div className="w-full flex justify-center items-center gap-10 md:gap-20">
          {/* Yes Option */}
          <label className="flex items-center gap-4 cursor-pointer" onClick={() => handleFieldUpdate(true)}>
            <input
              type="radio"
              value="Yes"
              checked={selectedValue === "Yes"}
              className="hidden"
              id="antiObesityYes"
              onChange={() => {}}
            />
            {/* Reusable YesSVG */}
            <YesSVG selected={selectedValue === "Yes"} />
            <span className="text-2xl" style={{ fontSize: fontSizes.buttonText }}>
              Yes
            </span>
          </label>

          {/* No Option */}
          <label className="flex items-center gap-4 cursor-pointer" onClick={() => handleFieldUpdate(false)}>
            <input
              type="radio"
              name="antiObesityMedications"
              value="No"
              checked={selectedValue === "No"}
              className="hidden"
              id="antiObesityNo"
              onChange={() => {}}
            />
            <NoSVG selected={selectedValue === "No"} />
            <span className="text-2xl" style={{ fontSize: fontSizes.buttonText }}>
              No
            </span>
          </label>
        </div>
      </Box>
    </div>
  );
};

export default Allergyglp;
