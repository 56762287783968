import Image from "../assets/mwm/disqualified.webp";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
import SectionActionButton from "../components/microcomponents/SectionActionButton";
import { setFormReset } from "../lib/store/actions/formAction";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import useResponsiveFonts from "../hooks/useResponsiveFonts";
import backgroundImage from "../assets/mwm/bg-m.svg";
export default function DisQualified({ availableHeight, availableWidth, imageScale }: any) {
  const form = useSelector(selectFormState, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const desktopFontConfig = useMemo(
    () => ({
      mainText: 120, // Base font size in px for <h1>
      subText: 30, // Base font size in px for <p> acting as <h2>
      bodyText: 25, // Base font size in px for regular <p>
      button: 19, // Base font size in px for buttons
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      mainText: 28, // Base font size in px for <h1>
      subText: 17, // Base font size in px for <p> acting as <h2>
      bodyText: 11, // Base font size in px for regular <p>
      button: 14, // Base font size in px for buttons
    }),
    []
  );

  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800, // baseHeight for desktop
    1200, // baseWidth for desktop
    600, // baseHeight for mobile
    400, // baseWidth for mobile
    12, // minFontSize
    72, // maxFontSize
    768 // mobile breakpoint
  );

  const handleBack = () => {
    dispatch(setFormReset());
    navigate("/", { replace: true });
  };

  return (
    <div className="xs:h-auto md:h-screen grid grid-cols-1 lg:grid-cols-2 xs:mt-8 md:mt-0" style={{ height: availableWidth < 600 ? "100%" : availableHeight }}>
      <div
        className="flex flex-col justify-center items-start xs:px-10 lg:px-20 bg-white "
        style={{
          height: availableWidth < 600 ? "100%" : availableHeight,
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "60% 55%",
        }}
      >
        <div className="md:hidden xs:block">
          <img src={Image} alt="Modern Weight loss" className="object-cover w-full h-full xs:scale-[122%]" />
        </div>
        <div className="w-full xs:mt-12 md:mt-4">
          <h1 style={{ fontSize: fontSizes.mainText, lineHeight: 1.2 }} className="font-bold text-[#333] mb-4">
            Thanks for completing our quiz,{" "}
            <span className="text-[#376C74] font-turbinado">
              {`${form.FirstName}`} {`${form.LastName}.`}
            </span>
          </h1>

          <p style={{ fontSize: fontSizes.subText, lineHeight: 1.3 }} className="bg-[#333] text-transparent bg-clip-text ">
            Unfortunately you're not eligible for FORM at this time.
          </p>

          <p style={{ fontSize: fontSizes.bodyText, lineHeight: 1.5 }} className="mt-2 text-[#333]">
            Our program is not appropriate for patients with certain medical conditions.
          </p>

          <SectionActionButton
            onClick={handleBack}
            text="Back to Home"
            className="mt-4 px-6 py-3 text-lg w-full !rounded-none border md:w-[80%] border-[#333] text-[#376C74] bg-[#E3F5FB] hover:bg-[#376C74] hover:text-white"
            fontSize={fontSizes.button}
            disabled={false}
          />
        </div>
      </div>
      <div className="relative " style={{ height: availableWidth < 600 ? "100%" : availableHeight }}>
        <img src={Image} alt="People at the beach" className="hidden sm:block w-full h-full object-cover md:mb-[-5rem] lg:mb-[-2.8rem] xl:mb-0 " />
      </div>
    </div>
  );
}
