import React from "react";

interface YesSVGProps {
  selected: boolean;
}
interface NoSVGProps {
  selected: boolean;
}

export const YesSVG: React.FC<YesSVGProps> = ({ selected }) => {
  return selected ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
      <rect x="1.03219" y="1.10543" width="24.5356" height="24.7432" rx="4.5388" fill="#F1F4FF" stroke="url(#paint0_linear_yes_selected)" strokeWidth="1.2968" />
      <rect x="6.8418" y="6.96777" width="12.9162" height="13.02" rx="2.5936" fill="#0B1838" />
      <defs>
        <linearGradient id="paint0_linear_yes_selected" x1="22.7309" y1="6.6244" x2="37.7044" y2="11.8833" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2B3A67" />
          <stop offset="1" stopColor="#F0AB35" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.03219" y="1.10543" width="24.5356" height="24.7432" rx="4.5388" fill="white" stroke="url(#paint0_linear_yes_unselected)" strokeWidth="1.2968" />
      <defs>
        <linearGradient id="paint0_linear_yes_unselected" x1="22.7309" y1="6.6244" x2="37.7044" y2="11.8833" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2B3A67" />
          <stop offset="1" stopColor="#F0AB35" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const NoSVG: React.FC<NoSVGProps> = ({ selected }) => {
  return selected ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
      <rect x="1.03219" y="1.10543" width="24.5356" height="24.7432" rx="4.5388" fill="#F1F4FF" stroke="url(#paint0_linear_no_selected)" strokeWidth="1.2968" />
      <rect x="6.8418" y="6.96777" width="12.9162" height="13.02" rx="2.5936" fill="#0B1838" />
      <defs>
        <linearGradient id="paint0_linear_no_selected" x1="22.7309" y1="6.6244" x2="37.7044" y2="11.8833" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2B3A67" />
          <stop offset="1" stopColor="#F0AB35" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.03219" y="1.10543" width="24.5356" height="24.7432" rx="4.5388" fill="white" stroke="url(#paint0_linear_no_unselected)" strokeWidth="1.2968" />
      <defs>
        <linearGradient id="paint0_linear_no_unselected" x1="22.7309" y1="6.6244" x2="37.7044" y2="11.8833" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2B3A67" />
          <stop offset="1" stopColor="#F0AB35" />
        </linearGradient>
      </defs>
    </svg>
  );
};
