import React, { useMemo, useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
import { setFormValue } from "../lib/store/actions/formAction";
import { useNavigate } from "react-router-dom";
import useResponsiveFonts from "../hooks/useResponsiveFonts";

const disqualifyingConditions: string[] = [
  "Diabetic Retinopathy",
  "Multiple Endocrine Neoplasia",
  "Diabetic Ketoacidosis",
  "Kidney disease/kidney insufficiency or transplant/Acute Kidney Injury",
  "Pancreatitis",
  "Gallbladder Disease",
  "Bariatric Surgery or other GI Surgery",
  "Leber Hereditary Optic Neuropathy",
  "Liver Disease/Cirrhosis",
];

interface HealthHistoryProps {
  availableHeight: number;
  availableWidth: number;
  marginTop?: number;
}

const HealthHistory: React.FC<HealthHistoryProps> = ({ availableHeight, availableWidth }) => {
  const form: any = useSelector(selectFormState, shallowEqual); 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const question = form.Dynamic_Questions.map((q: any) => (
    (q: any) => q.Main_Question === 'Do you have a personal medical history involving any of the following medical conditions?'
  ));

  const [selectedOption, setSelectedOption] = useState<string>(question?.Answer_ID || "");

  const handleFieldUpdate = useCallback(
    (value: string) => {
      setSelectedOption(value);
      const updatedQuestions = form.Dynamic_Questions.map((q: any) => ({
        ...q,
        Sugestive: q.Sugestive.map((s: any) =>
          s.Main_Question === 'Do you have a personal medical history involving any of the following medical conditions?'
            ? { ...s, Answer_ID: value }
            : s
        ),
      }));
      dispatch(
        setFormValue({
          field: "Dynamic_Questions",
          value: updatedQuestions,
        })
      );
  
      if (value === "No existing disqualifying factors") {
        navigate("/pregnancy");
      } else {
        navigate("/disqualified");
      }
    },
    [dispatch, form.Dynamic_Questions, navigate]
  );

  const desktopFontConfig = useMemo(
    () => ({
      heading: 32,
      subheading: 20,
      buttonText: 18,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 19,
      subheading: 14,
      buttonText: 14,
    }),
    []
  );

  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800,
    1200,
    600,
    400,
    12,
    72,
    768
  );

  return (
    <div
      className="flex flex-col items-center xs:my-6 md:mt-16"
      style={{
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <div className="xs:w-[90%] md:w-full md:max-w-xl lg:max-w-6xl mx-auto">
        <h2 className="font-bold text-left md:text-center text-[#333]" style={{ fontSize: fontSizes.heading }}>
          Do you have a <span className="font-bold">personal medical history</span> involving any of the following <span className="font-bold">medical conditions</span>?
        </h2>

        <p className="text-gray-500 xs:mb-2 text-left md:text-center md:mb-8" style={{ fontSize: fontSizes.subheading }}>
          It's important to understand your health history to determine if you're eligible.
        </p>

        <button
          className={`w-full mb-4 xs:mb-4 md:mb-4 mt-2 px-4 py-3 xs:text-left md:text-center border-2 transition-colors ${
            selectedOption === "No existing disqualifying factors" ? "bg-blue-100 border-blue-500" : "border-gray-300 bg-light-color hover:text-white hover:bg-button-bg"
          }`}
          onClick={() => handleFieldUpdate("No existing disqualifying factors")}
          style={{ fontSize: fontSizes.buttonText, borderWidth: "1px", transition: "background-color 0.2s, color 0.2s" }}
        >
          None of these
        </button>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {disqualifyingConditions.map((condition, index) => (
            <button
              key={index}
              className={`w-full px-4 py-3 text-left border-2 text-[#0B1838] transition-colors ${
                selectedOption === condition ? "bg-blue-100 border-blue-500" : "border-gray-300 bg-light-color hover:text-white hover:bg-button-bg"
              }`}
              onClick={() => handleFieldUpdate(condition)}
              style={{ fontSize: fontSizes.buttonText, borderWidth: "1px", transition: "background-color 0.2s, color 0.2s" }}
            >
              {condition}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HealthHistory;