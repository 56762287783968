import { useState, useEffect, useCallback, useMemo, ChangeEvent } from "react";
import SectionActionButton from "../components/microcomponents/SectionActionButton";
import { setFormValue } from "../lib/store/actions/formAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
import useResponsiveFonts from "../hooks/useResponsiveFonts";


const BMICalculator = ({ availableHeight, availableWidth }: any) => {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const [height, setHeight] = useState("");
  const [url, setUrl] = useState("");

  const handFieldUpdate = useCallback(
    (field: string, value: any) => {
      const payload = { field, value };
      dispatch(setFormValue(payload));

      // Update Dynamic_Questions when BMI values change
      if (field === "currentBMI" || field === "goalBMI") {
        const updatedDynamicQuestions = form.Dynamic_Questions.map((questionGroup: any) => {
          return {
            Sugestive: questionGroup.Sugestive.map((question: any) => {
              if (question.Main_Question === "What is your current and goal BMI?") {
                return {
                  ...question,
                  Answer_ID: `Current BMI: ${field === "currentBMI" ? value : form.currentBMI}, Goal BMI: ${field === "goalBMI" ? value : form.goalBMI}`,
                };
              }
              return question;
            }),
          };
        });
        dispatch(setFormValue({ field: "Dynamic_Questions", value: updatedDynamicQuestions }));
      }
    },
    [dispatch, form.Dynamic_Questions, form.currentBMI, form.goalBMI]
  );

  const heightFt = form.heightFt;
  const heightIn = form.heightIn;
  const Weight = form.Weight;
  const goalWeight = form.goalWeight;
  const currentBMI = form.currentBMI;
  const goalBMI = form.goalBMI;

  const [isInputValid, setIsInputValid] = useState(false);

  const calculateBMI = useCallback(
    (weight: any) => {
      if (heightFt && heightIn && weight) {
        const totalHeightInInches = parseFloat(heightFt) * 12 + parseFloat(heightIn);
        const bmiValue = (parseFloat(weight) / Math.pow(totalHeightInInches, 2)) * 703;
        return bmiValue.toFixed(2);
      }
      return null;
    },
    [heightFt, heightIn]
  );

  const setBMI = (value: any) => {
    handFieldUpdate("currentBMI", value);
  };

  const setGoalBMI = (value: any) => {
    handFieldUpdate("goalBMI", value);
  };

  const handleInchesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 0 && value <= 11) {
      handFieldUpdate("heightIn", value);
    } else if (e.target.value === "") {
      handFieldUpdate("heightIn", "");
    }
  };

  const updateCombinedHeight = useCallback(() => {
    if (heightFt && heightIn) {
      const formattedHeight = `${heightFt}'${heightIn}`;
      setHeight(formattedHeight);
      handFieldUpdate("Height", formattedHeight);
    }
  }, [heightFt, heightIn, handFieldUpdate]);

  useEffect(() => {
    const heightValid = parseFloat(heightFt) > 0 && parseFloat(heightIn) >= 0;
    const weightValid = parseFloat(Weight) > 0;

    if (heightValid && weightValid) {
      setIsInputValid(true);
      const bmiValue = calculateBMI(Weight);
      if (bmiValue) setBMI(bmiValue);
      updateCombinedHeight();
    } else {
      setIsInputValid(false);
      setBMI(null);
    }
  }, [calculateBMI, heightFt, heightIn, Weight]);

  useEffect(() => {
    const goalWeightValid = parseFloat(goalWeight) > 0;
    if (goalWeightValid) {
      const goalBmiValue = calculateBMI(goalWeight);
      if (goalBmiValue) setGoalBMI(goalBmiValue);
    } else {
      setGoalBMI(null);
    }
  }, [goalWeight, calculateBMI]);

  useEffect(() => {
    if (currentBMI >= 25) {
      setUrl("/allergysema");
    } else {
      setUrl("/disqualified");
    }
  }, [currentBMI]);

  const removeHeight = () => {
    setHeight("");
    handFieldUpdate("heightFt", "");
    handFieldUpdate("heightIn", "");
    handFieldUpdate("Height", "");
  };

  const desktopFontConfig = useMemo(
    () => ({
      heading: 29,
      label: 25,
      inputText: 21,
      buttonText: 19,
      errorText: 16,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 20,
      label: 17,
      inputText: 17,
      buttonText: 18,
      errorText: 16,
    }),
    []
  );

  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800,
    1200,
    600,
    400,
    12,
    72,
    768
  );

  const handelHeightChange = (e: any) => {
    setHeight(e.target.value);
    let value = e.target.value.split("'");
    handFieldUpdate("heightFt", parseInt(value[0]));
    handFieldUpdate("heightIn", parseInt(value[1]));
    handFieldUpdate("Height", e.target.value);
  }

  return (
    <div className="flex flex-col xs:mt-8 md:mt-16 xs:px-8 md:px-0 items-center m-auto max-w-6xl" style={{ fontSize: fontSizes.heading }}>
      <h2 style={{ fontSize: fontSizes.heading, lineHeight: 1.2 }} className="font-bold text-gray-800 text-center md:max-w-xl lg:max-w-6xl">
        Next, we'll calculate your Body Mass Index (BMI).
      </h2>

      <div className="flex flex-col md:flex-row md:items-center md:justify-center gap-6 md:gap-12 w-full xs:py-4 md:py-8">
        <div className="flex flex-col items-center">
          <label style={{ fontSize: fontSizes.label }} className="font-medium text-gray-900 mb-2">
            Height
          </label>
          {!(heightFt && heightIn && Weight) ? (
            <div className="flex space-x-4">
              <input
                type="text"
                placeholder="Feet"
                value={heightFt || ""}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (!isNaN(value) && value >= 0 && value <= 9) {
                    handFieldUpdate("heightFt", value);
                  } else if (e.target.value === "") {
                    handFieldUpdate("heightFt", "");
                  }
                }}
                className="h-12 sm:w-24 md:w-28 xs:w-1/2 text-xl text-center px-3 py-2 bg-[#FBFBFB] border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                placeholder="Inches"
                value={heightIn || ""}
                onChange={handleInchesChange}
                className="h-12 sm:w-24 md:w-28 xs:w-1/2 text-xl text-center px-3 py-2 bg-[#FBFBFB] border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                style={{ fontSize: fontSizes.inputText }}
              />
            </div>
          ) : (
            <input
              type="text"
              value={height}
              // onClick={removeHeight}
              style={{ fontSize: fontSizes.inputText }}
              
              onChange={handelHeightChange}
              className="xs:w-1/2 h-12 md:w-52 md:w-full text-xl text-center px-3 py-2 border-2 bg-[#FBFBFB] border-gray-300 rounded-md focus:outline-none cursor-pointer"
            />
          )}
        </div>

        <div className="flex flex-col items-center">
          <label style={{ fontSize: fontSizes.label }} className="font-medium text-gray-900 mb-2">
            Weight
          </label>
          <input
            placeholder="lbs"
            value={Weight || ""}
            onChange={(e) => {
              const value = parseInt(e.target.value.replace(/\D/g, ""), 10);
              if (!isNaN(value) && value <= 900) {
                handFieldUpdate("Weight", value);
              } else if (e.target.value === "") {
                handFieldUpdate("Weight", "");
              }
            }}
            type="number"
            className={`h-12 ${
              heightFt && heightIn ? "md:w-full" : "md:w-52"
            } xs:w-1/2 text-xl text-center px-3 py-2 bg-[#FBFBFB] border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
            style={{ fontSize: fontSizes.inputText }}
          />
        </div>

        {currentBMI && Weight.toString().length >= 2 && (
          <>
            <div className="hidden md:flex md:mt-[2.5rem] items-center text-2xl font-bold">=</div>
            <div className="flex flex-col items-center">
              <label style={{ fontSize: fontSizes.label }} className="font-medium text-gray-900 mb-2">
                BMI
              </label>
              <div className="xs:w-1/2 text-center md:w-full px-4 py-2 text-xl rounded-lg text-white bg-[#376C74]" style={{ fontSize: fontSizes.inputText }}>
                {currentBMI}
              </div>
            </div>
          </>
        )}
      </div>

      {currentBMI && (
        <div className="mt-8 xs:w-full md:w-auto">
          <h2 style={{ fontSize: fontSizes.heading, lineHeight: 1.2 }} className="font-bold text-gray-800 text-center">
            Now tell us your Goal Weight.
          </h2>
          <div className="flex flex-col md:flex-row md:items-center md:justify-center gap-6 md:gap-12 xs:py-4 md:py-8">
            <div className="flex flex-col items-center">
              <label style={{ fontSize: fontSizes.label }} className="font-medium text-gray-900 mb-2">
                Goal Weight
              </label>
              <input
                placeholder="lbs"
                value={goalWeight || ""}
                onChange={(e) => {
                  const value = parseInt(e.target.value.replace(/\D/g, ""), 10);
                  if (!isNaN(value) && value <= 900 && value < Weight) {
                    handFieldUpdate("goalWeight", value);
                  } else if (e.target.value === "") {
                    handFieldUpdate("goalWeight", "");
                  }
                }}
                type="number"
                className={`h-12 ${
                  heightFt && heightIn ? "md:w-full" : "md:w-52"
                } xs:w-1/2 text-xl text-center px-3 py-2 bg-[#FBFBFB] border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                style={{ fontSize: fontSizes.inputText }}
              />
            </div>
            {goalBMI && !isNaN(goalBMI) && goalWeight.toString().length >= 2 && (
              <>
                <div className="hidden md:flex items-center md:mt-[2.5rem] text-2xl font-bold">=</div>
                <div className="flex flex-col items-center">
                  <label style={{ fontSize: fontSizes.label }} className="font-medium text-gray-900 mb-2">
                    BMI
                  </label>
                  <div className="px-4 py-2 text-xl rounded-lg w-1/2 text-center md:w-full text-white bg-[#376C74]">
                    {goalBMI}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <SectionActionButton
        text="Next"
        disabled={!isInputValid}
        className={`!rounded-none w-full font-bold ${
          isInputValid ? "bg-[#E3F5FB] text-[#376C74] hover:bg-[#376C74] hover:text-white" : "bg-white text-gray-500 border-[1px] border-solid border-[#2B3A67] cursor-not-allowed"
        }`}
        style={{ fontSize: fontSizes.buttonText }}
        _hover={{
          background: isInputValid ? "#376C74" : "",
          border: "none",
          textColor: isInputValid ? "text-white" : "white",
        }}
        buttonnavurl={url}
      />
    </div>
  );
};

export default BMICalculator;