import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import GPL from "./pages/GPL";
import StateOfResidence from "./pages/StateOfResidence";
import Info from "./pages/Info";
import Greetings from "./pages/Greetings";
import BMICalculator from "./pages/BMICalculator";
import Motivation from "./pages/Motivation";
import Change from "./pages/Change";
import GreatNews from "./pages/GreatNews";
import HealthHistory from "./pages/HealthHistory";
import CurrentMedications from "./pages/CurrentMedications";
import DisQualified from "./pages/DisQualified";
import Qualified from "./pages/Qualified";
import AllergySema from "./pages/AllergySema";
import Allergyglp from "./pages/Allergyglp";
import Pregnant from "./pages/Pregnant";
import Depression from "./pages/Depression";
import Alchol from "./pages/Alchol";
import FamilyHistoy from "./pages/FamilyHistory";
import Chemotherapy from "./pages/Chemotherapy";
import ComorbiditiesForm from "./pages/ComorbiditiesForm";

export const RoutePath = {
  home: "/",
  glp: "/glp",
  info: "/info",
  greetings: "/greetings",
  motivation: "/motivation",
  change: "/change",
  stateOfResidence: "/stateofresidence",
  // greatNews: "/greatnews",
  BMICalculator: "/bmi",
  healthHistory: "/healthhistory",
  comorbiditiesForm: "/comorbiditiesform",
  antiObesityMedicationQuestion: "/antiobesity",
  // currentMedications: "/currentmedications",
  qualified: "/qualified",
  disqualified: "/disqualified",
  allergysema: "/allergysema",
  allergyglp: "/allergyglp",
  pregnant: "/pregnancy",
  depression: "/depression",
  alcoholconsumption: "/alcoholconsumption",
  familyHistory: "/familyhistory",
  chemotherapy: "/chemotherapy",
};

export const AppRoutes = ({ availableHeight, marginTop, availableWidth }: any) => {
  return (
    <Routes>
      <Route path="/" element={<Home availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/glp" element={<GPL availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/info" element={<Info availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth}/>} />
      <Route path="/greetings" element={<Greetings availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/motivation" element={<Motivation availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth}/>} />
      <Route path="/change" element={<Change availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/stateofresidence" element={<StateOfResidence availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      {/* <Route path="/greatnews" element={<GreatNews availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} /> */}
      <Route path="/bmi" element={<BMICalculator availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth}/>} />
      <Route path="/healthhistory" element={<HealthHistory availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth}/>} />
      {/* <Route path="/currentmedications" element={<CurrentMedications availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth}/>} /> */}
      <Route path="/disqualified" element={<DisQualified availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/comorbiditiesform" element={<ComorbiditiesForm availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/qualified" element={<Qualified availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/allergysema" element={<AllergySema availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/allergyglp" element={<Allergyglp availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/pregnancy" element={<Pregnant availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/depression" element={<Depression availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/alcoholconsumption" element={<Alchol availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/familyhistory" element={<FamilyHistoy availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
      <Route path="/chemotherapy" element={<Chemotherapy availableHeight={availableHeight} marginTop={marginTop} availableWidth={availableWidth} />} />
    </Routes>
  );
};
