import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const SectionActionButton = ({ text, ...props }: any) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (props.disabled) {
      return;
    }
    if (props.buttonnavurl) {
      navigate(props.buttonnavurl);
    } else {
      props.onClick?.();
    }
  };

  return (
    <Button
      px={10}
      py={12}
      borderRadius="16px"
      transition="all 0.1s ease"
      className={`${props.additionalclass} !rounded-none xs:w-full mx-auto md:m-0 md:w-[80%] p-0 font-bold sm:fixed xs:my-4 md:mb-0 md:mt-8 bottom-0 ${
        !props.disabled ? "bg-[#E3F5FB] text-[#376C74]" : "bg-white text-gray-500 border-[1px] border-solid border-[#2B3A67] cursor-not-allowed"
      }`}
      _hover={{
        background: !props.disabled ? "#376C74" : "",
        border: "none",
        textColor: props.disabled ? "text-white" : "white",
      }}
      cursor={props.disabled ? "not-allowed" : "pointer"}
      {...props}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};

export default SectionActionButton;
