import { SET_CLIENT_PATIENT_ID, SET_FORM_FIELD, SET_FORM_RESET } from './actionTypes';

export const setFormValue = (data: { field: string; value: string | number | boolean | null | string[] }) => ({
  type: SET_FORM_FIELD,
  payload: data,
});

export const setFormReset = () => ({
  type: SET_FORM_RESET,
});

export const setClientPatientID = (id: string) => ({
  type: SET_CLIENT_PATIENT_ID,
  payload: {
    id,
  },
});