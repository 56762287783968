export const SET_FORM_FIELD = 'SET_FORM_FIELD';
export const SET_FORM_RESET = 'SET_FORM_RESET';
export const SET_CLIENT_PATIENT_ID = 'SET_CLIENT_PATIENT_ID';

export interface SetFormFieldAction {
  type: typeof SET_FORM_FIELD;
  payload: {
    field: string;
    value: any;
  };
}

export const setFormValue = (payload: any) => {
  return {
    type: SET_FORM_FIELD,
    payload: payload,
  };
};

export interface SetFormResetAction {
  type: typeof SET_FORM_RESET;
}

export interface SetClientPatientIDAction {
  type: typeof SET_CLIENT_PATIENT_ID;
  payload: {
    id: string;
  };
}

export type FormActionTypes =
  | SetFormFieldAction
  | SetFormResetAction
  | SetClientPatientIDAction;