import SectionActionButton from "../components/microcomponents/SectionActionButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { selectFormState } from "../lib/store/selectors/formSelector";
import Image from "../assets/mwm/qualified.png";
import { setFormReset } from "../lib/store/actions/formAction";
import { useNavigate } from "react-router-dom";
import useResponsiveFonts from "../hooks/useResponsiveFonts";
import backgroundImage from "../assets/mwm/bg-m.svg";
import arrowRight from "../assets/arrow_right.svg";
import graph from "../assets/mwm/weightgraph.svg";
import { calculateAge, calculateGoalDate, combinedOperation } from "../utils/utils";

export default function Qualified({ availableHeight, availableWidth }: any) {
  let button = ["Semaglutide", "Tirzepatide"];
  const form = useSelector(selectFormState, shallowEqual);
  console.log("Qualified form data", form);
  const [medicine, setMedicine] = useState<string>("Semaglutide");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [localWeight, setLocalWeight] = useState(form.Weight);
  const [localGoalWeight, setLocalGoalWeight] = useState(form.goalWeight);
  const [localResultDate, setLocalResultDate] = useState("");

  const { Height, Gender, DOB } = form;
  const age = DOB.length > 0 ? calculateAge(DOB) : "";

  const desktopFontConfig = useMemo(
    () => ({
      mainText: 150,
      subText: 50,
      bodyText: 35,
      button: 19,
      turbText: 120,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      mainText: 50,
      subText: 30,
      bodyText: 16,
      button: 18,
      turbText: 59,
    }),
    []
  );

  const fontSizes = useResponsiveFonts(desktopFontConfig, mobileFontConfig, availableHeight, availableWidth, 800, 1200, 600, 400, 12, 72, 768);

  const closeModal = () => {
    setShowPaymentModal(false);
  };

  const handleCreateAccount = async () => {
    const apiUrl = `http://localhost:8000/user_basic_registration`;

    // Set the modal and ensure local state values are not overwritten
    setShowPaymentModal(true);

    let obj = combinedOperation(form, ["currentBMI", "goalBMI", "goalWeight", "heightFt", "heightIn"], ["Weight"]);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });

      if (!response.ok) {
        throw new Error("Failed to create account");
      }

      const result = await response.json();
      console.log("Account created successfully:", result);

      // Reset the form, but local state persists
      dispatch(setFormReset());
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Error creating account:", error);
    }
  };

  useEffect(() => {
    // Calculate and set the goal date based on local state values
    if (localWeight && localGoalWeight) {
      let result = calculateGoalDate(localWeight, localGoalWeight, medicine);
      setLocalResultDate(result.goalDate);
    }
  }, [medicine, localWeight, localGoalWeight]);

  return (
    <div className="xs:h-auto md:h-screen grid grid-cols-1 lg:grid-cols-2 xs:mt-4 md:mt-0">
      <div
        className="flex flex-col justify-center items-start xs:px-10 lg:pl-20 bg-white"
        style={{
          height: availableWidth < 600 ? "100%" : availableHeight,
          backgroundImage: availableWidth > 600 ? `url(${backgroundImage})` : "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "60% 55%",
        }}
      >
        <div className="w-full xs:mt-2 md:mt-0">
          <h1 style={{ fontSize: fontSizes.mainText, lineHeight: 1.2 }} className="font-bold font-turbinado text-[#376C74]">
            Great news,{" "}
            <span>
              {`${form.FirstName}`} {`${form.LastName}`}
            </span>
            !
          </h1>

          <p style={{ fontSize: fontSizes.subText, lineHeight: 1.3 }} className="bg-[#333] text-transparent font-bold bg-clip-text ">
            You're eligible.
          </p>

          <p style={{ fontSize: fontSizes.bodyText, lineHeight: 1.5 }} className="md:mt-8 text-[#333]">
            Next, let's create your account.
          </p>

          <div className="md:hidden xs:block mt-4">
            <img src={Image} alt="Modern Weight loss" className="object-cover w-full h-full" />
          </div>

          <SectionActionButton
            onClick={handleCreateAccount}
            text="Pay for Initial Consultation"
            className="mt-4 px-6 py-3 text-lg w-full !rounded-none border md:w-[80%] border-[#333] text-[#376C74] bg-[#E3F5FB] hover:bg-[#376C74] hover:text-white"
            fontSize={fontSizes.button}
            disabled={false}
          />
        </div>
      </div>

      <div className="relative block text-center">
        {availableWidth > 600 && <img src={Image} alt="woman smiling" className="w-full h-1/2 object-cover" />}
        <div className="sm:absolute xs:relative sm:w-[80%] sm:h-[65%] bg-gray-100 sm:m-auto sm:p-6 xs:m-6 xs:mt-6 xs:p-2 right-0 left-0 mx-auto transform sm:-translate-y-[7rem] sm:-top-10 z-10">
          <div className="grid grid-cols-1 gap-3">
            <h4 className="text-center">
              <span className="text-xl font-inter text-[#333]">Here's what to expect from your</span> <span className="text-4xl font-bold font-turbinado text-[#376C74]">Treatment plan.</span>
            </h4>
            {availableWidth > 600 && (
              <>
                <div className="grid grid-cols-5">
                  <div className="left col-span-3 gap-4">
                    <div className="flex gap-2">
                      {button.map((item, i) => (
                        <button key={i} onClick={() => setMedicine(item)} className={`w-1/2 p-2 ${medicine === item ? "bg-[#376C74] text-white" : "bg-gray-200 text-gray-700"}`}>
                          {item}
                        </button>
                      ))}
                    </div>
                    <div>
                      <p className="py-2">
                        See how much you could lose with <strong>{medicine}</strong>:
                      </p>
                      <div className="flex justify-between py-2">
                        <div>
                          <p className="text-[0.9rem]">Current Weight</p>
                          <p>
                            <span className="text-xl text-[#376C74]">{localWeight}</span> lbs
                          </p>
                        </div>
                        <div>
                          <img src={arrowRight} width={50} alt="" />
                        </div>
                        <div>
                          <p className="text-[0.9rem]">Goal Weight</p>
                          <p>
                            <span className="text-xl text-[#376C74]">{localGoalWeight}</span> lbs
                          </p>
                        </div>
                      </div>
                      <div className="image-container relative">
                        <img src={graph} alt="" />
                        <p className="absolute top-[30%] left-[10%]">{localWeight} lbs</p>
                        <p className="absolute top-[60%] left-[10%] text-xs px-[0.5rem] bg-[#376C74] text-white">{localGoalWeight - localWeight} lbs</p>
                      </div>
                    </div>
                  </div>
                  <div className="right col-span-2 gap-4">
                    <div className="flex justify-around">
                      <div>
                        <p className="text-sm">Age</p>
                        <p className="text-md text-[#376C74]">{age}</p>
                      </div>
                      <div>
                        <p className="text-sm">Sex/Gender</p>
                        <p className="text-md text-[#376C74]">{Gender}</p>
                      </div>
                      <div>
                        <p className="text-sm">Height</p>
                        <p className="text-md text-[#376C74]">{Height}</p>
                      </div>
                    </div>
                    <div className="py-8">
                      <h5 className="text-xl font-bold">You Could Lose Up to</h5>
                      <h2 className="text-4xl font-bold text-[#376C74]">
                        <span>{localWeight - localGoalWeight}</span> <span>lbs</span>
                      </h2>
                      <h5 className="text-xl">by {localResultDate}</h5>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1">
                  <p className="text-xs">
                    *Results depend on following a reduced-calorie diet and increasing physical activity. Individual outcomes may vary. For educational purposes only. Medication selection does not
                    necessarily reflect the final prescription.
                  </p>
                </div>
              </>
            )}
            {availableWidth < 600 && (
              <div className="grid grid-cols-1 gap-3">
                <div className="grid grid-cols-1 gap-2">
                  {button.map((item, i) => (
                    <button key={i} onClick={() => setMedicine(item)} className={`w-full p-2 ${medicine === item ? "bg-[#376C74] text-white" : "bg-gray-200 text-gray-700"}`}>
                      {item}
                    </button>
                  ))}
                </div>

                <div className="grid grid-cols-1 gap-2">
                  <div className="flex justify-around">
                    <div>
                      <p className="text-sm">Age</p>
                      <p className="text-md text-[#376C74]">{age}</p>
                    </div>
                    <div>
                      <p className="text-sm">Sex/Gender</p>
                      <p className="text-md text-[#376C74]">{Gender}</p>
                    </div>
                    <div>
                      <p className="text-sm">Height</p>
                      <p className="text-md text-[#376C74]">{Height}</p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-2">
                  <p className="py-2">
                    See how much you could lose with <strong>{medicine}</strong>:
                  </p>
                  <div className="flex justify-between py-2">
                    <div>
                      <p className="text-[0.9rem]">Current Weight</p>
                      <p>
                        <span className="text-xl text-[#376C74]">{localWeight}</span> lbs
                      </p>
                    </div>
                    <div>
                      <img src={arrowRight} width={50} alt="" />
                    </div>
                    <div>
                      <p className="text-[0.9rem]">Goal Weight</p>
                      <p>
                        <span className="text-xl text-[#376C74]">{localGoalWeight}</span> lbs
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-5 gap-2">
                  <div className="py-2 col-span-2">
                    <h5 className="text-xs font-bold">You Could Lose Up to</h5>
                    <h2 className="text-xl font-bold  text-[#376C74]">
                      <span>{localWeight - localGoalWeight}</span> <span>lbs</span>
                    </h2>
                    <h5 className="text-xs">by {localResultDate}</h5>
                  </div>
                  <div className="col-span-3 image-container relative">
                    <img src={graph} className="h-24" alt="" />
                    <p className="absolute top-[40%] left-[10%] text-xs">{localWeight} lbs</p>
                    <p className="absolute top-[60%] left-[10%] text-[0.6rem] px-[0.5rem] bg-[#376C74] text-white">{localGoalWeight - localWeight} lbs</p>
                  </div>
                </div>
                <div className="grid grid-cols-1">
                  <p className="text-xs">
                    *Results depend on following a reduced-calorie diet and increasing physical activity. Individual outcomes may vary. For educational purposes only. Medication selection does not
                    necessarily reflect the final prescription.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Payment Modal */}
      {showPaymentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg overflow-hidden w-full max-w-3xl h-[600px] relative">
            <button onClick={closeModal} className="top-4 right-4 text-gray-600 hover:text-gray-800 m-[1rem]">
              ✕
            </button>
            <div className="p-0 h-full">
              <h2 className="text-lg md:text-xl lg:text-2xl font-bold mb-4 text-center">Complete Your Payment</h2>
              <iframe
                src="https://pglnk.io/widget/form/8O2aAmUOJVBM8iaghbys"
                style={{
                  width: "100%",
                  height: "calc(100% - 2rem)",
                  border: "none",
                  borderRadius: "3px",
                }}
                id="inline-8O2aAmUOJVBM8iaghbys"
                data-layout="{'id':'INLINE'}"
                data-trigger-type="alwaysShow"
                data-activation-type="alwaysActivated"
                data-deactivation-type="neverDeactivate"
                data-form-name="P3 Method | Initial Visit"
                data-layout-iframe-id="inline-8O2aAmUOJVBM8iaghbys"
                data-form-id="8O2aAmUOJVBM8iaghbys"
                title="P3 Method | Initial Visit"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
