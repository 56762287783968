import { useMemo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
import { setFormValue } from "../lib/store/actions/formAction";
import useResponsiveFonts from "../hooks/useResponsiveFonts";

const timeframes = [
  "0-2 drinks per week",
  "3-5 drinks per week",
  "1-2 drinks per day",
  "more than 2 drinks per day",
];

export default function Alcohol({ availableHeight, availableWidth, marginTop }: any) {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const question = form.Dynamic_Questions.find((q: any) => q.Main_Question === 'Do you currently consume alcohol?');

  const [selectedOption, setSelectedOption] = useState<string>(question?.Answer_ID || "");

  const selectedQuestion = timeframes.findIndex((option) => option === selectedOption);

  const handleFieldUpdate = useCallback(
    (value: string) => {
      setSelectedOption(value);

      // Update the Answer_ID for the alcohol consumption question
      const updatedQuestions = form.Dynamic_Questions.map((q: any) => ({
        ...q,
        Sugestive: q.Sugestive.map((s: any) =>
          s.Main_Question === 'Do you currently consume alcohol?'
            ? { ...s, Answer_ID: value }
            : s
        ),
      }));

      dispatch(
        setFormValue({
          field: "Dynamic_Questions",
          value: updatedQuestions,
        })
      );

      // Check the eligibility based on BMI and alcohol consumption
      const bmi = form.currentBMI;
      let isEligible = false;

      if (bmi >= 30) {
        if (value === "0-2 drinks per week" || value === "3-5 drinks per week") {
          isEligible = true;
        }
      } else if (bmi >= 27 && bmi < 30) {
        const hasComorbidity = form.comorbidities && form.comorbidities.length > 0;
        if (
          (value === "0-2 drinks per week" || value === "3-5 drinks per week") &&
          hasComorbidity
        ) {
          isEligible = true;
        }
      }

      // Navigate based on eligibility
      if (isEligible) {
        navigate("/familyhistory");
      } else if (
        value === "1-2 drinks per day" ||
        value === "more than 2 drinks per day"
      ) {
        navigate("/disqualified");
      } else {
        navigate("/familyhistory");
      }
    },
    [dispatch, form.Dynamic_Questions, form.currentBMI, form.comorbidities, navigate]
  );

  const desktopFontConfig = useMemo(
    () => ({
      heading: 32,
      button: 22,
      buttonText: 18,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 24,
      button: 20,
      buttonText: 14,
    }),
    []
  );

  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800,
    1200,
    600,
    400,
    12,
    72,
    768
  );

  return (
    <div className="flex flex-col items-center xs:mt-12 md:mt-24 xs:px-8 md:px-0">
      <h2
        style={{ fontSize: fontSizes.heading, lineHeight: 1.3 }}
        className="font-bold xs:text-left md:text-center mb-6"
      >
        Do you <span className="font-bold">currently</span> consume alcohol?
      </h2>

      {/* Selection Buttons */}
      <div className="w-full max-w-3xl space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {timeframes.map((option, index) => (
            <button
              key={index}
              className={`px-4 py-4 text-left border-2 transition-colors duration-200 ${
                index === selectedQuestion
                  ? "bg-light-color border-gray-300"
                  : "border-gray-300 bg-light-color hover:text-white hover:bg-button-bg"
              }`}
              onClick={() => handleFieldUpdate(option)}
              style={{
                borderWidth: "1px",
                transition: "background-color 0.2s, color 0.2s",
              }}
            >
              <span style={{ fontSize: fontSizes.buttonText }} className="block">
                {option}
              </span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}