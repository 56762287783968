import React, { useCallback, useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { Select, Button, Input } from "@chakra-ui/react";
import StatesData from "../data/listOfState.json";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
import { setFormValue } from "../lib/store/actions/formAction";
import useResponsiveFonts from "../hooks/useResponsiveFonts";

interface ElementProps {
  name: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  validation?: any;
  options?: { value: string | number; label: string }[];
  styles?: string;
}

type StateOfResidenceProps = {
  availableHeight: number;
  availableWidth: number;
  marginTop: number;
};

const StateOfResidence: React.FC<StateOfResidenceProps> = ({ availableHeight, availableWidth, marginTop }) => {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ZipCode, setZipCode] = useState(form.ZipCode || "");
  const [ZipCodeError, setZipCodeError] = useState("");
  const [isZipCodeValid, setIsZipCodeValid] = useState(false);
  const [userState, setUserState] = useState(false);
  const [Address1, setAddress1] = useState(form.Address1 || "");
  const [AddressError, setAddressError] = useState("");

  const handFieldUpdate = useCallback(
    (field: string, value: string) => {
      const payload = { field, value };
      dispatch(setFormValue(payload));
    },
    [dispatch]
  );

  const element: ElementProps = {
    name: "SelectState",
    label: "What state do you live in?",
    placeholder: "Select a state",
    defaultValue: "",
    validation: { required: true },
    options: StatesData.States.map((state) => ({
      value: state.value,
      label: state.value,
    })),
  };

  const myState = form.State;

  const { control } = useForm();

  const detectState = async () => {
    try {
      const res = await axios.get(`https://pro.ip-api.com/json/?fields=61439&key=uOSpLaMSM0Tpxvv`);
      if (res.data && res.data.regionName) {
        // handFieldUpdate("regionName", res.data.regionName);
        handFieldUpdate("State", res.data.regionName);
        handFieldUpdate("City", res.data.city);
      }
    } catch (error) {
      console.error("Error fetching state from IP:", error);
      handFieldUpdate("State", "");
      handFieldUpdate("City", "");
    }
  };

  useEffect(() => {
    detectState();
    if (ZipCode.length === 5) {
      setIsZipCodeValid(true);
    }
  }, []);

  const updateState = () => {
    setUserState(!userState);
  };

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    setZipCode(numericValue);
    handFieldUpdate("ZipCode", numericValue);
    if (numericValue.length === 5) {
      setZipCodeError("");
      setIsZipCodeValid(true);
    } else {
      setZipCodeError("Please enter a valid 5-digit zip code.");
      setIsZipCodeValid(false);
    }
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;

    // Basic validation: Check if the input is empty or too short
    if (input.trim() === "") {
      setAddressError("Address cannot be empty.");
    } else if (input.length < 5) {
      setAddressError("Address is too short.");
    } else {
      setAddressError(""); // Clear the error if the input is valid
    }

    setAddress1(input);

    // Dispatch the updated address to the Redux store
    dispatch(setFormValue({ field: "Address1", value: input }));
  };

  const handleNextClick = () => {
    if (isZipCodeValid) {
      navigate("/bmi");
    }
  };

  const desktopFontConfig = useMemo(
    () => ({
      heading: 32, // Base font size in px for <h1>
      subheading: 32, // Base font size in px for <h2>
      label: 28, // Base font size in px for labels
      buttonText: 19, // Base font size in px for button text
      inputText: 25, // Base font size in px for input text
      errorText: 14,
      subquestion: 24, // Desktop font size for buttons
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 22, // Base font size in px for <h1>
      subheading: 16, // Base font size in px for <h2>
      label: 14, // Base font size in px for labels
      buttonText: 14, // Base font size in px for button text
      inputText: 16, // Base font size in px for input text
      errorText: 14,
      subquestion: 14, // Mobile font size for buttons
    }),
    []
  );

  // Use the custom hook to get scaled font sizes
  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800, // baseHeight for desktop
    1200, // baseWidth for desktop
    600, // baseHeight for mobile
    400, // baseWidth for mobile
    12, // minFontSize
    72, // maxFontSize
    768 // mobile breakpoint
  );
  return (
    <div className={`xs:mt-12 sm:mt-24 my-3 m-auto xs:px-8 sm:px-0`} style={{ fontSize: fontSizes.heading }}>
      {/* Main Heading */}
      <h1 style={{ fontSize: fontSizes.heading, lineHeight: 1.2 }} className="text-center mb-4">
      What is your <span className="text-[#333] font-bold">state</span> and <span className="text-[#333] font-bold">zip code</span>?
      </h1>

      <p  className="text-center text-gray-600 mb-8 max-w-4xl" style={{ fontSize: fontSizes.subquestion }}>
      Help us find a provider in your area!
        </p>

      {/* State Detection Section */}
      <div className="mb-4 text-center">
        <label style={{ fontSize: fontSizes.label }}>{myState || "Select your state"}</label>
        {myState ? (
          <Button
            className="ml-4 py-[0.4rem] px-[0.6rem] text-white"
            style={{
              background: "#376C74",
              fontSize: fontSizes.buttonText,
            }}
            onClick={updateState}
          >
            Change
          </Button>
        ) : (
          <p style={{ fontSize: fontSizes.label }}>We couldn't detect your state. Please choose below.</p>
        )}
      </div>

      {/* State Selection Dropdown */}
      {(userState || !myState) && (
        <div key={element.name} className="mb-4">
          <Controller
            name={element.name}
            control={control}
            defaultValue={element.defaultValue || myState}
            rules={{ ...element.validation }}
            render={({ field }) => (
              <div className="text-center">
                {element.label && (
                  <span style={{ fontSize: fontSizes.subheading }} className="font-bold mb-4 block">
                    {element.label}
                  </span>
                )}
                <Select
                  {...field}
                  placeholder={element.placeholder || "Select a state"}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handFieldUpdate("state", e.target.value);
                  }}
                  iconSize="0"
                  value={field.value || myState || ""}
                  size="md"
                  className={`border-[2px] text-center py-2 rounded-lg !border-[#2B3A67] ${field.value ? "!bg-white" : ""}`}
                  style={{ fontSize: fontSizes.inputText }}
                >
                  {element?.options?.map((item, idx) => (
                    <option key={idx} value={item.value}>
                      {item.label || item.value}
                    </option>
                  ))}
                </Select>
              </div>
            )}
          />
        </div>
      )}

      {/* Zip Code Input Section */}
      <div className="xs:text-center md:text-left mt-8">
        <h3 style={{ fontSize: fontSizes.subheading }}>Zip Code</h3>
        <Input
          placeholder="Enter zip code"
          value={ZipCode}
          onChange={handleZipCodeChange}
          size="lg"
          maxLength={5}
          className="md:w-full px-3 py-2 border-b-2 text-center border-gray-300 !rounded-lg focus:border-transparent focus:outline-none focus:border-b-indigo-300"
          style={{ fontSize: fontSizes.inputText, background: "#FBFBFB" }}
        />
        {ZipCodeError && (
          <p style={{ fontSize: fontSizes.errorText }} className="text-red-500 text-sm mt-1">
            {ZipCodeError}
          </p>
        )}
      </div>

      <div className="xs:text-center md:text-left mt-8">
        <h3 style={{ fontSize: fontSizes.subheading }}>Your Address</h3>
        <input
          type="text"
          placeholder="Enter address"
          value={Address1}
          onChange={handleAddressChange}
          maxLength={100}
          className="md:w-full px-3 py-2 border-b-2 text-center border-gray-300 rounded-lg focus:border-transparent focus:outline-none focus:border-b-indigo-300"
          style={{ fontSize: fontSizes.inputText, background: "#FBFBFB" }}
        />
        {AddressError && (
          <p style={{ fontSize: fontSizes.errorText }} className="text-red-500 text-sm mt-1">
            {AddressError}
          </p>
        )}
      </div>

      {/* Next Button */}
      <div className="text-center mt-6">
        <Button
          px={10}
          py={12}
          borderRadius="16px"
          transition="all 0.1s ease"
          className={`!rounded-none w-full font-bold ${
            isZipCodeValid ? "bg-[#E3F5FB] text-[#376C74] hover:bg-[#376C74] hover:text-white" : "bg-white text-gray-500  border-[1px] border-solid border-[#2B3A67] cursor-not-allowed"
          }`}
          style={{
            fontSize: fontSizes.buttonText,
          }}
          onClick={handleNextClick}
          disabled={!isZipCodeValid}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default StateOfResidence;
