import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
import { setFormValue } from "../lib/store/actions/formAction";
import useResponsiveFonts from "../hooks/useResponsiveFonts";

const motivations = ["Improve health", "Increase energy", "Look and feel better", "Manage a health condition", "Other"];

export default function Motivation({ availableHeight, availableWidth, marginTop }: any) {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const desktopFontConfig = useMemo(
    () => ({
      heading: 32,
      button: 22,
      buttonText: 18,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 24,
      button: 20,
      buttonText: 14,
    }),
    []
  );

  const fontSizes = useResponsiveFonts(desktopFontConfig, mobileFontConfig, availableHeight, availableWidth, 800, 1200, 600, 400, 12, 72, 768);

  const selectedQuestion = motivations.findIndex((question) => question === form.motivation);

  const handleSelection = (selection: string) => {
    const updatedDynamicQuestions = form.Dynamic_Questions.map((questionGroup : any) => {
      return {
        Sugestive: questionGroup.Sugestive.map((question : any) => {
          if (question.Main_Question === "What is your motivation for weight loss?") {
            return { ...question, Answer_ID: selection };
          }
          return question;
        }),
      };
    });

    dispatch(setFormValue({ field: "Dynamic_Questions", value: updatedDynamicQuestions }));
    navigate("/change");
  };

  return (
    <div className="flex flex-col items-center xs:mt-12 md:mt-24 xs:px-8 md:px-0">
      <h2 style={{ fontSize: fontSizes.heading, lineHeight: 1.3 }} className="font-bold xs:text-left md:text-center mb-6">
        What is your <span className="font-bold">top motivation</span> for losing weight?
      </h2>

      {/* Motivation Buttons */}
      <div className="w-full max-w-3xl space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {motivations.map((motivation, index) => (
            <button
              key={index}
              className={`px-4 py-4 text-left border-2  transition-colors duration-200 ${
                index === selectedQuestion ? "bg-light-color border-gray-300" : "border-gray-300 bg-light-color hover:text-white hover:bg-button-bg"
              }`}
              onClick={() => handleSelection(motivation)}
              style={{
                borderWidth: "1px",
                transition: "background-color 0.2s, color 0.2s",
              }}
            >
              <span style={{ fontSize: fontSizes.buttonText }} className="block">
                {motivation}
              </span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
