import backgroundImage from "../../assets/mwm/bg-m.svg";

const SectionSpliter = ({ sectionRight, sectionLeft, availableHeight, availableWidth, className, lClassName }: any) => {
  return (
    <div className={`${lClassName ? lClassName : ""} grid grid-cols-1 md:grid md:grid-cols-2 h-full`} style={{ height: availableWidth < 600 ? "100%" : availableHeight }}>
      <div
        className="flex flex-col justify-center h-full pl-8 xs:px-0 md:pl-10 lg:pl-10 xl:px-20 z-10 3xl:mt-8"
        style={{
          height: availableWidth < 600 ? "100%" : availableHeight,
          backgroundImage: availableWidth > 600 ? `url(${backgroundImage})` : '',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "65% 10%",
        }}
      >
        {sectionLeft}
      </div>

      <div
        className={`${className ? className : ""} flex flex-col justify-end h-full pl-8 xs:px-10 md:pl-10 lg:pr-0 lg:pl-10 xl:pl-20`}
        style={{ height: availableWidth < 600 ? "100%" : availableHeight }}
      >
        {sectionRight}
      </div>
    </div>
  );
};

export default SectionSpliter;
