import {
  SET_FORM_FIELD,
  SET_FORM_RESET,
  SET_CLIENT_PATIENT_ID,
  FormActionTypes,
} from '../actions/actionTypes';

interface FormState {
  UserName: string;
  UserSecret: string;
  ClientPatientID: string;
  FirstName: string;
  LastName: string;
  Gender: string;
  ChiefComplaint: string;
  Pharmacy_Name: string;
  Pharmacy_Address: string;
  Pharmacy_City: string;
  Pharmacy_State: string;
  Pharmacy_Zip: string;
  Pharmacy_Phone: string;
  Rx1_PCN_Number: string;
  Is_Async: string;
  Dynamic_Questions: any[];
  Email: string;
  HomePhone: string;
  Address1: string;
  City: string;
  State: string;
  ZipCode: string;
  DOB: string;
  Height: string;
  Weight: string;

}

const initialState: FormState = {
  UserName: "api@modernthyroidclinic.com",
  UserSecret: "USAmwl!98dd",
  ClientPatientID: "",
  FirstName: "",
  LastName: "",
  Address1: "",
  City: "",
  State: "",
  ZipCode: "",
  DOB: "",
  Height: "",
  Weight: "",
  Email: "",
  HomePhone: "",
  Gender: "",
  ChiefComplaint: "mwl1-weightloss",
  Pharmacy_Name: "DIRX PHARMACY",
  Pharmacy_Address: "3540 NW 56th St, Suite 204Ft",
  Pharmacy_City: "Lauderdale",
  Pharmacy_State: "FL",
  Pharmacy_Zip: "33309",
  Pharmacy_Phone: "5123689171",
  Rx1_PCN_Number: "5707801",
  Is_Async: "Y",
  Dynamic_Questions: [
    {
      Sugestive: [
        { Main_Question: "Have you ever had an adverse or allergic reaction to another GLP-1 receptor agonist?", Answer_ID: "" },
        {
          Main_Question:
            "Have you ever had an adverse or allergic reaction to Semaglutide, or to any of its ingredients? such as, but without limitation to, Dulaglutide (Trulicity), Exenatide extended release (Bydureon bcise), Exenatide (Byetta), Liraglutide (Victoza, Saxenda), and Lixisenatide (Adylyxin)?",
          Answer_ID: "",
        },
        { Main_Question: "Do you have a personal medical history involving any of the following medical conditions?", Answer_ID: "" },
        { Main_Question: "Are you currently pregnant, trying to get pregnant, or breastfeeding?", Answer_ID: "" },
        { Main_Question: "Are you currently experiencing, or have you experienced, depression with a history of suicidal attempts, suicidal thoughts, or suicidal ideation?", Answer_ID: "" },
        { Main_Question: "Do you currently consume alcohol?", Answer_ID: "" },
        { Main_Question: "Do you have any family history of Multiple Endocrine Neoplasia, Type 2, (MEN 2) or Medullary Thyroid Carcinoma (MTC)?", Answer_ID: "" },
        { Main_Question: "Are you currently receiving Chemotherapy?", Answer_ID: "" },
        { Main_Question: "What is your motivation for weight loss?", Answer_ID: "" },
        { Main_Question: "What is your current and goal BMI?", Answer_ID: "" },
        { Main_Question: "Do any exsisting medical comorbidities apply to you?", Answer_ID: "" },
      ],
    },
  ],
}


const formReducer = (state = initialState, action: FormActionTypes): FormState => {
  switch (action.type) {
    case SET_FORM_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case SET_CLIENT_PATIENT_ID:
      return {
        ...state,
        ClientPatientID: action.payload.id,
      };
    case SET_FORM_RESET:
      return initialState;
    default:
      return state;
  }
};

export default formReducer;