import { useEffect, useState } from 'react';

type FontConfig = {
  [key: string]: number; 
};

type ScaledFontSizes = {
  [key: string]: string; 
};

const useResponsiveFonts = (
  desktopFontConfig: FontConfig,    // Font config for desktop
  mobileFontConfig: FontConfig,     // Font config for mobile
  availableHeight: number,
  availableWidth: number,
  baseHeightDesktop: number = 800,
  baseWidthDesktop: number = 1200,
  baseHeightMobile: number = 600,
  baseWidthMobile: number = 400,
  minFontSize: number = 12,
  maxFontSize: number = 200,
  mobileBreakpoint: number = 768    // Breakpoint for mobile
): ScaledFontSizes => {
  const [scaledFonts, setScaledFonts] = useState<ScaledFontSizes>({});

  useEffect(() => {
    // Determine if it's mobile or desktop based on width
    const isMobile = availableWidth <= mobileBreakpoint;

    // Use different fontConfig and base sizes for mobile/desktop
    const fontConfig = isMobile ? mobileFontConfig : desktopFontConfig;
    const baseHeight = isMobile ? baseHeightMobile : baseHeightDesktop;
    const baseWidth = isMobile ? baseWidthMobile : baseWidthDesktop;
    // Calculate scaling factors
    const heightScalingFactor = availableHeight / baseHeight;
    const widthScalingFactor = availableWidth / baseWidth;
     
    // Choose the smaller scaling factor to maintain aspect ratio
    const scalingFactor = Math.min(heightScalingFactor, widthScalingFactor);
    // Scale each font size and clamp within min and max limits
    const newScaledFonts: ScaledFontSizes = {};
    Object.entries(fontConfig).forEach(([key, baseSize]) => {
      const scaledSize = baseSize * scalingFactor;
      const clampedSize = Math.max(minFontSize, Math.min(scaledSize, maxFontSize));
      newScaledFonts[key] = `${clampedSize.toFixed(2)}px`;
    });

    setScaledFonts(newScaledFonts);
  }, [desktopFontConfig, mobileFontConfig, availableHeight, availableWidth, baseHeightDesktop, baseWidthDesktop, baseHeightMobile, baseWidthMobile, minFontSize, maxFontSize, mobileBreakpoint]);

  return scaledFonts;
};

export default useResponsiveFonts;