import React, { useMemo, useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setFormValue } from "../lib/store/actions/formAction";
import { selectFormState } from "../lib/store/selectors/formSelector";
import { useNavigate } from "react-router-dom";
import useResponsiveFonts from "../hooks/useResponsiveFonts";
import SectionActionButton from "../components/microcomponents/SectionActionButton";

const healthConditions: string[] = [
  "Hypertension (HTN)",
  "Elevated Blood Pressure",
  "Polycystic Ovary Syndrome (PCOS)",
  "Insulin Resistance (IR)",
  "Hyperlipidemia (HLD)",
  "Obstructive Sleep Apnea",
  "Arthritis",
  "Depression",
  "Pre-Diabetes",
  "Type 2 Diabetes Mellitus (Type 2 DM)",
  "Non-Alcoholic Steatohepatitis (NASH)",
  "Metabolic Syndrome",
  "Cardiovascular Disease",
];

interface ComorbiditiesFormProps {
  availableHeight: number;
  availableWidth: number;
  marginTop?: number;
}

const ComorbiditiesForm: React.FC<ComorbiditiesFormProps> = ({ availableHeight, availableWidth }) => {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const question = form.Dynamic_Questions.map((q: any) => (
    (q: any) => q.Main_Question === 'Do any exsisting medical comorbidities apply to you?'
  ));

  const [selectedOption, setSelectedOption] = useState<string>(question?.Answer_ID || "");
  const [selectedComorbidities, setSelectedComorbidities] = useState<string[]>(form.comorbidities || []);


  const handleSubmit = useCallback(
    (string?: string) => {
      // setSelectedOption(value);
      console.log(">>> here are the selected comorbidities", selectedComorbidities);
      const updatedQuestions = form.Dynamic_Questions.map((q: any) => ({
        ...q,
        Sugestive: q.Sugestive.map((s: any) =>
          s.Main_Question === 'Do any exsisting medical comorbidities apply to you?'
            ? { ...s, Answer_ID: string ? string : [...selectedComorbidities] }
            : s
        ),
      }));
      dispatch(
        setFormValue({
          field: "Dynamic_Questions",
          value: updatedQuestions,
        })
      );

    const bmi = form.currentBMI;
    if (bmi === undefined || bmi === null) {
      alert("BMI is not calculated. Please complete the BMI calculator first.");
      return;
    }

    if (bmi >= 30) {
      navigate("/qualified");
    } else if (bmi >= 27 && bmi < 30) {
      if (selectedComorbidities.length >= 1) {
        navigate("/qualified");
      } else {
        navigate("/disqualified");
      }
    } else if (bmi >= 25 && bmi < 27) {
      navigate("/qualified");
    } else {
      navigate("/disqualified");
    }
  }, [dispatch, form.Dynamic_Questions, navigate, selectedComorbidities]);

  const handleNoneSelected = useCallback((input: string) => {
    setSelectedComorbidities(prev => [...prev, input]);
    handleSubmit(input); 
  }, [handleSubmit]);


  const toggleComorbidities = useCallback((condition: string) => {
    setSelectedComorbidities((prev) => {
      if (prev.includes(condition)) {
        return prev.filter((item) => item !== condition);
      } else {
        return [...prev, condition];
      }
    });
  }, []);

  const desktopFontConfig = useMemo(
    () => ({
      heading: 32,
      subheading: 20,
      buttonText: 18,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 19,
      subheading: 14,
      buttonText: 14,
    }),
    []
  );

  const fontSizes = useResponsiveFonts(desktopFontConfig, mobileFontConfig, availableHeight, availableWidth, 800, 1200, 600, 400, 12, 72, 768);

  return (
    <div
      className="flex flex-col items-center xs:my-6 md:mt-16"
      style={{
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <div className="xs: w-[90%] md:w-full lg:max-w-6xl mx-auto">
        {/* Heading */}
        <h2 className="font-bold text-left md:text-center text-[#333]" style={{ fontSize: fontSizes.heading }}>
        Do any of the following apply to you?
        </h2>

        {/* Subheading/Paragraph */}
        <p className="text-gray-500 xs:mb-2 md:mb-4 text-left md:text-center" style={{ fontSize: fontSizes.subheading }}>
        It's important to understand your health history to determine if you're eligible.
        </p>

       {/* "None of these" Option */}
       {selectedComorbidities.length === 0 && (
            <button
              className={`w-full mb-4 mt-2 px-4 py-3 text-left md:text-center border-2 rounded-lg transition-colors ${
                selectedComorbidities.length === 0 ? "bg-[#376C74] border-[#376C74] text-white" : "bg-gray-50 border-gray-300 hover:bg-[#376C74] hover:text-white"
              }`}
              onClick={() => handleNoneSelected("No existing Comorbidities conditions")}
              style={{ fontSize: fontSizes.buttonText }}
            >
              None of these
            </button>
        )}

        {/* Comorbidities Selection */}
        <div className="mb-6">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {healthConditions.map((condition, index) => (
              <button
                key={index}
                className={`w-full px-4 py-3 text-[#0B1838] text-left border-2 rounded-lg transition-colors ${
                  selectedComorbidities.includes(condition) ? "bg-[#376C74] border-[#376C74] text-white" : "bg-gray-50 border-gray-300 hover:bg-[#376C74] hover:text-white"
                }`}
                onClick={() => toggleComorbidities(condition)}
                style={{ fontSize: fontSizes.buttonText }}
              >
                {condition}
              </button>
            ))}
          </div>
        </div>

        {/* Submit Button */}
        {selectedComorbidities.length > 0 && (
          <SectionActionButton text="Next"  additionalclass="!w-full" onClick={handleSubmit} style={{ fontSize: fontSizes.buttonText }} />
        )}
      </div>
    </div>
  );
};

export default ComorbiditiesForm;
