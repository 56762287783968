import { Box } from "@chakra-ui/react";
import SectionMainText from "../microcomponents/SectionMainText";
import SectionActionButton from "../microcomponents/SectionActionButton";
import SectionSpliter from "../microcomponents/SectionSpliter";
import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import useResponsiveFonts from "../../hooks/useResponsiveFonts";

const SectionLeft = ({ mainText, description, buttonText, turbText, imageMobile, buttonnavurl, isCheckboxVisible, availableHeight, availableWidth }: any) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const desktopFontConfig = useMemo(
    () => ({
      mainText: 75, // Desktop font size for <h2>
      description: 22, // Desktop font size for <p>
      button: 19, // Desktop font size for buttons
      turbText: 120,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      mainText: 30, // Mobile font size for <h2>
      description: 11, // Mobile font size for <p>
      button: 14, // Mobile font size for buttons
      turbText: 59,
    }),
    []
  );

  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800, // baseHeight for desktop
    1200, // baseWidth for desktop
    600, // baseHeight for mobile
    400, // baseWidth for mobile
    12, // minFontSize
    300, // maxFontSize
    768 // mobile breakpoint
  );

  const onButtonClick = () => {
    navigate(buttonnavurl);
  };

  return (
    <div className="flex flex-1 flex-col items-start justify-between xs:mt-0 2xl:mt-10 3xl:mt-0">
      <div className="md:hidden xs:block w-full">
        <img src={imageMobile} alt="Modern Weight loss" className="object-cover w-full h-full" />
      </div>
      <div className="xs:px-10 sm:px-0">
      <SectionMainText text={mainText} fontSize={fontSizes.mainText} />
      <span className="font-turbinado text-[#376C74]"  style={{ fontSize: fontSizes.turbText, lineHeight: `${1.1 * parseFloat(fontSizes.turbText)}px` }}>
        {turbText}
      </span>
      <div className="flex flex-1 flex-col items-start justify-start  md:mt-10 xs:mr-0 xl:mr-20">
        <Box display="flex" flexDirection="row" gap={10} alignItems="start">
          {isCheckboxVisible && (
            <input
              style={{ lineHeight: `${1 * parseFloat(fontSizes.description)}px` }}
              type="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              className="md:scale-[130%] xs:mt-0 md:mt-[0.3rem]"
              id="scales"
              name="scales"
            />
          )}
          <span style={{ fontSize: fontSizes.description, lineHeight: `${1.3 * parseFloat(fontSizes.description)}px` }} className="text-[#111928] font-sofia">
            {description}
          </span>
        </Box>
        <SectionActionButton disabled={!isChecked && isCheckboxVisible} text={buttonText} onClick={onButtonClick} fontSize={fontSizes.button} />
      </div>
      </div>
    </div>
  );
};

const SectionRight = ({ imageUrl }: any) => {
  return (
    <div className="sm:flex sm:justify-center lg:justify-end w-full h-full xs:hidden">
      <img src={imageUrl} alt="Modern Weight loss" className="max-w-full max-h-full object-cover" />
    </div>
  );
};

const ImageWithText = ({
  mainText,
  imageUrl,
  imageMobile,
  description,
  buttonText,
  marginTop,
  availableHeight,
  className,
  availableWidth,
  buttonnavurl,
  turbText,
  isCheckboxVisible = true,
  imageScale,
}: any) => {
  return (
    <div className="md:overflow-hidden overflow-auto h-full md:h-screen" style={{ height: availableWidth < 600 ? "100%" : availableHeight }}>
      <SectionSpliter
        availableHeight={availableHeight}
        availableWidth={availableWidth}
        marginTop={marginTop}
        className={className}
        sectionLeft={
          <SectionLeft
            mainText={mainText}
            isCheckboxVisible={isCheckboxVisible}
            description={description}
            buttonText={buttonText}
            turbText={turbText}
            buttonnavurl={buttonnavurl}
            imageMobile={imageMobile}
            availableHeight={availableHeight}
            availableWidth={availableWidth}
          />
        }
        sectionRight={<SectionRight imageUrl={imageUrl} imageScale={imageScale} availableHeight={availableHeight} />}
      />
    </div>
  );
};

export default ImageWithText;
