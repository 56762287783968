import React from "react";
import { RoutePath } from "../../AppRoutes";
import { useLocation } from "react-router-dom";

const progressPercentage: Record<string, string> = {
  [RoutePath.home]: "1%",
  [RoutePath.glp]: "5%",
  [RoutePath.info]: "10%",
  [RoutePath.greetings]: "15%",
  [RoutePath.motivation]: "20%",
  [RoutePath.change]: "25%",
  [RoutePath.stateOfResidence]: "30%",
  [RoutePath.BMICalculator]: "35%",
  [RoutePath.healthHistory]: "55%",
  [RoutePath.comorbiditiesForm]: "90%",
  [RoutePath.allergysema]: "40%",
  [RoutePath.allergyglp]: "50%",
  [RoutePath.pregnant]: "60%",
  [RoutePath.depression]: "70%",
  [RoutePath.alcoholconsumption]: "75%",
  [RoutePath.familyHistory]: "80%",
  [RoutePath.chemotherapy]: "85%",
  [RoutePath.qualified]: "100%",
  [RoutePath.disqualified]: "100%",
};
interface ProgressProps {
  percentage?: string;
}

const Progress = React.forwardRef<HTMLDivElement, ProgressProps>(({ percentage }, ref) => {
  const location = useLocation();
  const path = location.pathname;
  const progressString = percentage || progressPercentage[path] || "1%";
  const progressValue = parseFloat(progressString); 
  const isSmallProgress = progressValue <= 10;

  return (
    <div className="h-2 bg-gray-300 mt-6 relative">
      <div
        className="h-2 bg-[#78C5D2] rounded-full relative"
        style={{ width: progressString }}
      >
        <span
          className={`absolute text-[#376C74] whitespace-nowrap ${
            isSmallProgress ? "right-[-1.2rem]" : "right-0"
          }`}
          style={{ bottom: "6px" }}
        >
          {progressString}
        </span>
      </div>
    </div>
  );
});

export default Progress;