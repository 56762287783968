import ImageWithText from "../components/macrocomponents/ImageWithText";
import Image from "../assets/mwm/change.png";
import { shallowEqual, useSelector } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
export default function Change({ availableHeight, marginTop, availableWidth }: any) {
  const form = useSelector(selectFormState, shallowEqual);

  return (
    <ImageWithText
      mainText={`We Hear You,`}
      imageUrl={Image}
      isCheckboxVisible={false}
      turbText={`${form.FirstName}.`}
      imageMobile={Image}
      description="We know how important it is to feel your best, and we're here to help you get there. Let's work together to achieve the lasting change you've been looking for"
      buttonText="Next"
      availableHeight={availableHeight}
      availableWidth={availableWidth}
      marginTop={marginTop}
      buttonnavurl="/stateofresidence"
      className="xs:hidden md:block"
    />
  );
}

