import ImageWithText from "../components/macrocomponents/ImageWithText";
import Image from "../assets/mwm/home.png";
// import Imagemob from '../assets/mwm/home'
export default function Home({availableHeight, marginTop, availableWidth}: any) {

  return (
    <ImageWithText
      mainText="Your journey to sustainable health"
      imageUrl={Image}
      imageMobile={Image}
      turbText="starts here."
      buttonnavurl="/glp"
      description="By clicking ‘Continue’, you agree that Modern Weight Loss may use your responses to personalize your experience and for other purposes as described in our Privacy Policy. Responses prior to account creation will not be used as part of your medical assessment."
      buttonText="Get Started"
      availableHeight={availableHeight}
      availableWidth={availableWidth}
      marginTop={marginTop}
      className="xs:hidden md:block"
    />
  );
}