import { shallowEqual, useSelector } from "react-redux";
import ImageWithText from "../components/macrocomponents/ImageWithText";
import Image from "../assets/mwm/greetings.png"
import { selectFormState } from "../lib/store/selectors/formSelector";


export default function Greetings({availableHeight, marginTop, availableWidth}: any) {
  const form = useSelector(selectFormState, shallowEqual);
  return (
    <ImageWithText
      mainText={`Nice to meet you,`}
      imageUrl={Image}
      isCheckboxVisible={false}
      turbText={`${form.FirstName}.`}
      imageMobile={Image}
      description="We're Modern Weight Loss – a team dedicated to precise, personalized and proven solutions that work for YOU."
      buttonText="Next"
      availableHeight={availableHeight}
      availableWidth={availableWidth}
     marginTop={marginTop}
      buttonnavurl="/motivation"
      className="xs:hidden md:block"
    />
  );
}

