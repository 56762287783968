import React, { useState, useCallback, Suspense, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
import { setClientPatientID, setFormValue } from "../lib/store/actions/formAction";
import { debounce } from "lodash";
import emailValidation from "./validations/emailValidation";
import { useNavigate } from "react-router-dom";
import SectionActionButton from "../components/microcomponents/SectionActionButton";
import useResponsiveFonts from "../hooks/useResponsiveFonts";

const genderOptions = ["Choose your gender", "Male", "Female"];

interface InfoProps {
  availableHeight: number;
  availableWidth: number;
  marginTop?: number;
}

const Info: React.FC<InfoProps> = ({ availableHeight, availableWidth, marginTop }) => {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [dateError, setDateError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const handFieldUpdate = useCallback(
    (field: string, value: string) => {
      const payload = { field, value };
      dispatch(setFormValue(payload));
    },
    [dispatch]
  );

  const validateName = (name: string) => {
    const regex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
    return name.length >= 2 && regex.test(name);
  };

  const validateDate = (date: string) => {
    const today = new Date();
    const selectedDate = new Date(date);

    if (selectedDate > today) {
      setDateError("Date of birth cannot be in the future.");
      return false;
    }

    const minAgeDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    if (selectedDate > minAgeDate) {
      setDateError("You must be at least 16 years old.");
      return false;
    }

    setDateError(""); // Clear error if valid
    return true;
  };

  const validateEmail = (email: any) => {
    const isValid = emailValidation(email);
    setEmailError(!isValid ? "Please enter a valid email address" : "");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedValidateEmail = useCallback(
    debounce((email: string) => {
      validateEmail(email);
    }, 300),
    []
  );

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handFieldUpdate("FirstName", value);
    if (!validateName(value)) {
      setFirstNameError("First name must be at least 2 characters and contain only letters.");
    } else {
      setFirstNameError("");
    }
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handFieldUpdate("LastName", value);
    if (!validateName(value)) {
      setLastNameError("Last name must be at least 2 characters and contain only letters.");
    } else {
      setLastNameError("");
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value;
    if (validateDate(selectedDate)) {
      handFieldUpdate("DOB", selectedDate);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handFieldUpdate("Email", value);
    debouncedValidateEmail(value);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 3 && value.length <= 6) {
      value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
    } else if (value.length > 6) {
      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
    handFieldUpdate("HomePhone", value);
  };

  const validatePhoneNumber = () => {
    const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (!phonePattern.test(form.HomePhone)) {
      setPhoneError("Please enter a valid phone number (e.g., (123) 456-7890).");
    } else {
      setPhoneError("");
    }
  };

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      if (
        !emailError &&
        !firstNameError &&
        !lastNameError &&
        !dateError &&
        form.FirstName &&
        form.Email &&
        form.DOB
      ) {
        try {
          const response = await fetch('https://modernweightlossapi.vercel.app/addContact', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: form.Email,
              firstName: form.FirstName,
              lastName: form.LastName,
              phone: form.HomePhone,
            }),
          });
  
          const data = await response.json();
  
          if (data.success) {
            const id = data.data.contact.id;
            dispatch(setClientPatientID(id));
            navigate('/greetings');
          } else {
            alert('Failed to add contact');
          }
        } catch (error) {
          console.error('Error adding contact:', error);
          alert('An error occurred. Please try again.');
        }
      } else {
        alert('Please ensure all details are valid.');
      }
    },
    [emailError, firstNameError, lastNameError, dateError, form, dispatch, navigate]
  );

  const desktopFontConfig = useMemo(
    () => ({
      heading: 30, // Desktop font size for <h2>
      buttonText: 19, // Desktop font size for buttons
      label: 16,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 24, // Mobile font size for <h2>
      buttonText: 14, // Mobile font size for buttons
      label: 13,
    }),
    []
  );

  console.log("form", form);

  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800, // baseHeight for desktop
    1200, // baseWidth for desktop
    600, // baseHeight for mobile
    400, // baseWidth for mobile
    12, // minFontSize
    72, // maxFontSize
    768 // mobile breakpoint
  );

  return (
    <div className="flex items-center justify-center xs:px-8 md:px-0">
      <div className="lg:max-w-2xl w-full xs:my-[1rem] md:mt-16">
        <h2 className="xs:text-xl md:text-3xl font-medium xs:text-left md:text-center xs:mb-4 md:mb-8 text-[#333]" style={{ fontSize: fontSizes.heading }}>
          Please <span className="font-bold">share your information</span> to get started.
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6 w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="firstName" className="block text-sm text-[#333] xs:text-sm xl:text-lg camelCase mb-2" style={{ fontSize: fontSizes.label }}>
                First Name
              </label>
              <input
                id="firstName"
                type="text"
                value={form.FirstName}
                onChange={handleFirstNameChange}
                className={`w-full xs:px-3 text-[#333] md:px-6 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                  firstNameError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
                }`}
                placeholder="Enter your first name"
                required
                style={{ fontSize: fontSizes.inputText, background: "#FBFBFB" }}
              />
              {firstNameError && (
                <p className="text-red-500 text-sm mt-1" style={{ fontSize: fontSizes.errorText }}>
                  {firstNameError}
                </p>
              )}
            </div>

            <div>
              <label htmlFor="lastName" className="block text-sm text-[#333] xs:text-sm xl:text-lg camelCase mb-2" style={{ fontSize: fontSizes.label }}>
                Last Name
              </label>
              <input
                id="lastName"
                type="text"
                value={form.LastName}
                onChange={handleLastNameChange}
                className={`w-full xs:px-3 md:px-6 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                  lastNameError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
                }`}
                placeholder="Enter your last name"
                required
                style={{ fontSize: fontSizes.inputText, background: "#FBFBFB" }}
              />
              {lastNameError && (
                <p className="text-red-500 text-sm mt-1" style={{ fontSize: fontSizes.errorText }}>
                  {lastNameError}
                </p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="dateOfBirth" className="block text-sm text-[#333] xs:text-sm xl:text-lg camelCase mb-2" style={{ fontSize: fontSizes.label }}>
                Date of Birth
              </label>
              <input
                id="dateOfBirth"
                onChange={handleDateChange}
                className={`w-full xs:px-3 md:px-6 py-2 text-[#333] border rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                  dateError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
                }`}
                type="date"
                max={new Date().toISOString().split("T")[0]}
                value={form.DOB}
                required
                style={{ fontSize: fontSizes.inputText, background: "#FBFBFB", WebkitAppearance: "none", width: "100%" }}
                dir="ltr"
              />
              {dateError && (
                <p className="text-red-500 text-sm mt-1" style={{ fontSize: fontSizes.errorText }}>
                  {dateError}
                </p>
              )}
            </div>
            <div className="relative ">
              <label htmlFor="gender" className="block text-sm text-[#333] xs:text-sm xl:text-lg camelCase mb-2" style={{ fontSize: fontSizes.label }}>
                Gender Assigned at Birth
              </label>
              <select
                id="gender"
                className="w-full xs:px-3 md:px-6 py-2 border text-[#333] rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 appearance-none"
                onChange={(e) => handFieldUpdate("Gender", e.target.value)}
                value={form.Gender}
                style={{
                  fontSize: fontSizes.inputText,
                  borderRadius: "10px",
                  paddingRight: "2.5rem",
                  background: "#FBFBFB",
                }}
              >
                {genderOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {/* Custom arrow */}
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center xs:pr-[1rem] md:pr-[1.6rem]" style={{ top: "40%" }}>
                <svg className="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="email" className="block xs:text-sm xl:text-lg text-[#333] camelCase mb-2" style={{ fontSize: fontSizes.label }}>
                Email
              </label>
              <input
                id="email"
                type="email"
                value={form.Email}
                onChange={handleEmailChange}
                onBlur={() => validateEmail(form.Email)}
                placeholder="Enter your email address"
                className={`w-full xs:px-3 md:px-6 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                  emailError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
                }`}
                required
                style={{ fontSize: fontSizes.inputText, background: "#FBFBFB" }}
              />
              {emailError && (
                <p className="text-red-500 text-sm mt-1" style={{ fontSize: fontSizes.errorText }}>
                  {emailError}
                </p>
              )}
            </div>
            <div>
              <label htmlFor="phone" className="block xs:text-sm xl:text-lg text-[#333] camelCase mb-2" style={{ fontSize: fontSizes.label }}>
                Phone Number
              </label>
              <input
                id="phone"
                type="tel"
                value={form.HomePhone}
                onChange={handlePhoneChange}
                onBlur={validatePhoneNumber}
                placeholder="Enter your phone number"
                className={`w-full xs:px-3 md:px-6 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                  phoneError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
                }`}
                required
                style={{ fontSize: fontSizes.inputText, background: "#FBFBFB" }}
              />
              {phoneError && (
                <p className="text-red-500 text-sm mt-1" style={{ fontSize: fontSizes.errorText }}>
                  {phoneError}
                </p>
              )}
            </div>
          </div>
                
          <div>
            <Suspense fallback={<div>Loading...</div>}>
              <SectionActionButton text="Next" onClick={handleSubmit} fontSize={fontSizes.buttonText} className="w-full !rounded-none bg-[#E3F5FB] text-[#376C74] " />
            </Suspense>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Info;