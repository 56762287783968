import { Link } from "react-router-dom";
import { Logo, BackArrow } from "../../assets";
import { forwardRef } from "react";
import { useNavigation } from "./NavigationContext";

const Header = forwardRef<HTMLDivElement>((props, ref) => {
  const { goBack } = useNavigation();

  return (
    <div ref={ref} className="flex z-30 justify-between fixed top-0 w-full items-center xs:px-[1rem] md:px-[2rem] lg:px-[5rem] md:py-6 xs:py-[1rem] bg-white">
      <button onClick={goBack}>
        <BackArrow />
      </button>
      <div className="flex flex-1 justify-center">
  <Link to="https://modernweightloss.com/">
    <Logo className="h-12 md:h-auto" />
  </Link>
</div>
    </div>
  );
});

export default Header;
