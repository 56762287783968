export const combinedOperation = (data: Record<string, any>, arr: string[], items: string[] = []): Record<string, any> => {
  for (let i = 0; i < arr.length; i++) {
    delete data[arr[i]];
  }
  for (let i = 0; i < items.length; i++) {
    data[items[i]] = data[items[i]].toString();
  }
  return data;
};

export function debounce<T extends (...args: any[]) => any>(func: T, delay: number): (...args: Parameters<T>) => void {
  let timeoutId: NodeJS.Timeout | null;

  return function (...args: Parameters<T>) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export function calculateAge(dateOfBirth: string) {
  // Parse the dateOfBirth input into a Date object
  const dob = new Date(dateOfBirth);
  const today = new Date();

  // Calculate the difference in years
  let age = today.getFullYear() - dob.getFullYear();

  // Adjust the age if the birthday hasn't occurred yet this year
  const monthDiff = today.getMonth() - dob.getMonth();
  const dayDiff = today.getDate() - dob.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
}

export const calculateGoalDate = (currentWeight: number, goalWeight: number, medicine: string): { weeks: number; goalDate: string } => {
  // Calculate the total weight loss needed
  const weightLoss = currentWeight - goalWeight;

  // Initialize the weekly loss based on the drug type
  let weeklyLoss = 0;

  // Set weekly loss rate based on the selected drug
  if (medicine === "Semaglutide") {
    weeklyLoss = currentWeight * 0.0075; // 0.75% of current weight per week
  } else if (medicine === "Tirzepatide") {
    weeklyLoss = currentWeight * 0.0125; // 1.25% of current weight per week
  }

  // Calculate the number of weeks needed to reach the goal
  const weeks = weightLoss / weeklyLoss;

  // Calculate the goal date by adding the weeks to the current date
  const goalDate = new Date();
  goalDate.setDate(goalDate.getDate() + Math.ceil(weeks * 7)); // Multiply weeks by 7 to convert to days

  // Format the date as MM/DD/YYYY
  const formattedGoalDate = `${String(goalDate.getMonth() + 1).padStart(2, "0")}/${String(goalDate.getDate()).padStart(2, "0")}/${goalDate.getFullYear()}`;

  return {
    weeks: Math.round(weeks * 100) / 100, // Round weeks to 2 decimal places
    goalDate: formattedGoalDate, // Format as MM/DD/YYYY
  };
};
